import 'react-input-range/lib/css/index.css';
import 'react-toastify/dist/ReactToastify.css';

import './assets/sass/global.scss';
import './assets/sass/main.scss';
import './assets/css/pe-icon-7-stroke.css';

import React from 'react';
import ReactDOM from 'react-dom';

import Bootstrap from './Bootstrap';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Bootstrap />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
