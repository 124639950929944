import i18N from '@i18N'
import configs from '@configs'

import Service from './Service'

let dict
if (!dict) {
  dict = new Service(configs, i18N)
}

const Translate = (props) => {
  const { word, parameters, children, html } = props
  /**/
  if (children) {
    return dict.translate(children, parameters, html)
  } else if (word) {
    return dict.translate(word, parameters, html)
  } else {
    return ''
  }
}

export { dict }
export default Translate
