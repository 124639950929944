import * as Semantic from 'semantic-ui-react'
import React, { Component, Children } from 'react'
import { Grid, Input, Card } from 'semantic-ui-react'

import Utils from '@utils'
import Load from '@UI/Load'
import Translate, { dict } from '@UI/Translate'

import TablePagination from './components/TablePagination'

//Styles
import './styles.scss'

class Table extends Component {
  static defaultProps = {
    headers: [],
    children: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      pageActual: 1,
      array: [],
    }
    this.pageItens = [
      { value: 0, name: 'TABLE_LIST_ALL' },
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 5, name: '5' },
      { value: 10, name: '10' },
      { value: 20, name: '20' },
      { value: 50, name: '50' },
    ]
    this.itens = []
    this.pages = []
    this.total = 0
    this.newPage = this.newPage.bind(this)
    this.pagination = this.pagination.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    this.setState(
      {
        pagination: this.props.pagination,
        headers: this.props.headers,
      },
      this.pagination(this.props)
    )
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      this.props.children !== props.children ||
      this.props.headers !== props.headers ||
      props.pagination === this.state.pagination
    ) {
      this.setState(
        {
          pagination: props.pagination,
          headers: props.headers,
        },
        this.pagination(props)
      )
    }
  }

  pagination(props, pageActual = this.state.pageActual, pagination = this.props.pagination) {
    if (!isNaN(pagination) && pagination) {
      this.itens = []
      this.pages = []
      this.total = 0
      let count = (pageActual - 1) * pagination
      Children.forEach(props.children, (child) => {
        if (this.total++ >= count && this.total <= count + pagination && child) {
          this.itens = [...this.itens, child]
        }
      })
      let totalPage = Math.ceil(this.total / pagination)
      for (let i = 1; i <= totalPage; i++) {
        this.pages = [...this.pages, i]
      }
      this.setState({ pageActual, pagination })
    } else {
      this.pages = []
      this.itens = props.children
    }
  }

  newPage(pageActual) {
    const { pageSize } = this.state
    this.pagination(this.props, pageActual, pageSize)
  }

  onChange(event) {
    const { pagination } = this.props
    const {
      target: { value, name },
    } = event
    if (!isNaN(pagination)) {
      this.setState(
        {
          [name]: value,
        },
        this.pagination(this.props, 1, value)
      )
    } else if (Utils.isObject(pagination.success) && value >= 0) {
      const data = Utils.getValue(pagination, 'success.data')
      if (value === 0) {
        event.pageSize = data.maxResults
      } else {
        event.pageSize = value
      }
      pagination.submit(event)
      this.setState({ [name]: value })
    }
  }

  orderBy(element, index) {
    const { orderBy } = this.props
    if (orderBy) {
      let { headers } = this.state
      let { value, status } = element
      status = status + 1
      if (status < 2) {
        headers[index].status = status
      } else {
        status = 0
        headers[index].status = status
      }
      headers = headers.filter((element, j) => (index !== j ? delete element.status : null))
      this.setState({ headers })
      this.props.orderBy(value, status)
    }
  }

  orderStatus(status) {
    switch (status) {
      case 0:
        return 'crescent'
      case 1:
        return 'decreasing'
      default:
        return ''
    }
  }

  footerTable = () => {
    const { load } = this.props
    const { headers } = this.state
    const length = this.itens.length
    if (load && !length) {
      return (
        <tr>
          <td style={{ padding: '15px' }} className="text-center" colSpan={headers.length}>
            <Load message="LOAD" />
          </td>
        </tr>
      )
    } else if (!load && !length) {
      return (
        <tr>
          <td style={{ padding: '15px' }} className="text-center" colSpan={headers.length}>
            <Translate>EMPTY_LIST</Translate>
          </td>
        </tr>
      )
    }
  }

  render() {
    let { pageActual, headers, pagination } = this.state

    const {
      onAdd,
      params,
      orderBy,
      component,
      className = '',
      onChange,
      noTraslate,
      disabled = true,
      showHeader = true,
      nameFilter = 'filter',
      label,
    } = this.props

    return (
      <div className={`Table ${className}`}>
        {showHeader && (
          <Card.Header className="card-header-search">
            <Grid columns="equal">
              <Grid.Column>
                <Input
                  icon="search"
                  iconPosition="left"
                  name={nameFilter}
                  onChange={onChange}
                  placeholder={label ? dict.translate(label) : dict.translate('TABLE_LIST_PLACEHOLDER_SEARCH_NAME')}
                />
              </Grid.Column>
              <Grid.Column className="custom-element">
                {onAdd && (
                  <button
                    onClick={onAdd}
                    type="button"
                    className={`${!disabled ? 'disabled' : ''} add-element purple box-shadow`}
                  >
                    +
                  </button>
                )}
                {component && component}
              </Grid.Column>
            </Grid>
          </Card.Header>
        )}
        <Semantic.Table compact striped>
          <thead>
            <tr>
              {Array.isArray(headers) &&
                headers.map((element, index) => (
                  <th
                    key={index}
                    className={`${orderBy ? 'arrow-order' : ''} ${element.class || ''} ${this.orderStatus(
                      element.status
                    )} `}
                    onClick={() => this.orderBy(element, index)}
                  >
                    {noTraslate && element.name}
                    {!noTraslate && <Translate>{element.name}</Translate>}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(this.itens) && this.itens.length ? this.itens : null}
            {this.footerTable()}
          </tbody>
        </Semantic.Table>
        <TablePagination
          params={params}
          pages={this.pages}
          pageActual={pageActual}
          pagination={pagination}
          newPage={this.newPage}
          pageItens={this.pageItens}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default Table
