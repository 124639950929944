import React, { useState, useEffect } from 'react'

import Image from '@UI/Image'
import Input from '@UI/Input'
import { dict } from '@UI/Translate'
import SellMilesUtils from '@utils/sellmiles.utils'
import AirlineConstants from '@constants/airline.contants'

// Styles
import './styles.scss'

const CardStepTwo = (props) => {
  const { airlineMap = {}, item } = props

  const [options, setOptions] = useState([])

  const anticipatedMethod = { payment: AirlineConstants.Anticipated }
  const programmedMethod = { payment: AirlineConstants.Programmed }

  useEffect(() => {
    const array = SellMilesUtils.options(airlineMap, item)
    const text = dict.translate('SELL_MILES_LABEL_NUMBER_TEXT')
    const options = array.map((item) => ({
      value: item,
      label: `${item} ${text}`,
    }))
    setOptions(options)
  }, [])

  const verifyInput = (price) => {
    if (item.price && !(item.price === price)) {
      return 'disabled_card_input'
    } else {
      return ''
    }
  }

  const salePrice = ({ amount, idAirline }, method) => {
    const { rules = [], percent } = airlineMap[idAirline]
    return SellMilesUtils.getPrice({ rules, amount, percent, method })
  }

  const check = (type) => {
    return item.price && item.price === type
  }

  const convertData = (value) => {
    return SellMilesUtils.convertData(value)
  }

  const anticipated = salePrice(item, 'inCash')
  const programmed = salePrice(item, 'programmed')

  return (
    <div className="CardStepTwo">
      <div className="cardStepTwo_content">
        <div className="cardStepTwo_content_image flex center-y center-x">
          <Image src={item.urlPhoto} size="small" alt="IMAGE" />
        </div>
        <div className="cardStepTwo_content_input flex center-y">
          <Input
            {...props}
            name="amount"
            type="select"
            array={options}
            nameValue="value"
            nameText="label"
            value={item.amount}
            label="SELL_MILES_CARD_STEP_ONE_TITLE"
          />
        </div>
        <div className="cardStepTwo_content_input_type">
          {item.amount && (
            <>
              <div className={`card_input ${verifyInput(anticipated)}`}>
                <Input
                  {...props}
                  name="price"
                  type="checkbox"
                  keys={anticipatedMethod}
                  nameValue={anticipated}
                  label={'PAGE_SELL_MILES_ANTECIPATED'}
                  value={check(anticipated)}
                />
                <span>{convertData(anticipated)}</span>
              </div>
              <div className={`card_input ${verifyInput(programmed)}`}>
                <Input
                  {...props}
                  name="price"
                  type="checkbox"
                  keys={programmedMethod}
                  nameValue={programmed}
                  value={check(programmed)}
                  label={'PAGE_SELL_MILES_PROGRAMED'}
                />
                <span>{convertData(programmed)}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardStepTwo
