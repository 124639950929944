import React from 'react'

import Image from '@UI/Image'
import * as Material from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'

import Utils from '@utils'

import './styles.scss'

const Autocomplete = (props = {}) => {
  const { popupIcon, helperText, image, value, name, label, array = [], nameValue = '', handleChange, disabled } = props

  const onChange = (event, item = {}) => {
    event.target = {
      ...event.target,
      name,
      item,
      value: Utils.getValue(item, nameValue),
    }
    handleChange(event)
  }

  const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option
    }
    if (option.inputValue) {
      return option.inputValue
    }
    return option[nameValue]
  }

  return (
    <Material.Autocomplete
      popupIcon={popupIcon}
      value={value}
      onChange={onChange}
      options={array}
      disabled={disabled}
      className="Autocomplete"
      getOptionLabel={getOptionLabel}
      renderOption={(option) => (
        <>
          <span className="flag">{image && <Image className="icon" src={option[image]} alt="IMAGE"/>}</span>
          {option[nameValue]}
        </>
      )}
      renderInput={(params) => <TextField {...params} helperText={helperText} label={label} />}
    />
  )
}

Autocomplete.defaultProps = {
  value: '',
  array: [],
  nameValue: '',
}

export default Autocomplete
