import React from 'react'
import { Grid, Image } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'

import './styles.scss'

const FormLoyaltyProgram = (props) => {
  const { formik, index, item, profile } = props
  const { handleBlur, handleChange, values = {}, errors } = formik
  const form = {
    name: `fidelity.${index}.name`,
    login: `fidelity.${index}.login`,
    password: `fidelity.${index}.password`,
  }
  return (
    <Grid className="FormLoyaltyProgram" stackable columns="equal">
      <Grid.Column className="card_loyalty_proram_content_image">
        <Image className="imag" src={item.urlPhoto} alt="BANNER" />
      </Grid.Column>
      <Grid.Column>
        <Input
          disabled
          {...props}
          name={form.name}
          onBlur={handleBlur}
          onChange={handleChange}
          value={Utils.getValue(values, form.name)}
          error={Utils.getValue(errors, form.name)}
          helperText={Utils.getValue(errors, form.name)}
          label="FORM_LOYALTY_PROGRAM_INPUT_LABEL_NAME"
        />
      </Grid.Column>
      <Grid.Column>
        <Input
          {...props}
          name={form.login}
          onBlur={handleBlur}
          onChange={handleChange}
          value={Utils.getValue(values, form.login)}
          error={Utils.getValue(errors, form.login)}
          helperText={Utils.getValue(errors, form.login)}
          label="FORM_LOYALTY_PROGRAM_INPUT_LABEL_LOGIN"
        />
      </Grid.Column>
      <Grid.Column>
        <Input
          {...props}
          name={form.password}
          onBlur={handleBlur}
          onChange={handleChange}
          type={profile ? 'password' : 'text'}
          value={Utils.getValue(values, form.password)}
          error={Utils.getValue(errors, form.password)}
          helperText={Utils.getValue(errors, form.password)}
          label="FORM_LOYALTY_PROGRAM_INPUT_LABEL_PASSWORD"
        />
      </Grid.Column>
    </Grid>
  )
}

export default FormLoyaltyProgram
