import React, { useState, useEffect } from 'react'

import axios from 'axios'
import Utils from '@utils'
import configs from '@configs'
import Toast from '@utils/toast.utils'
import ApiUtils from '@utils/api.utils'

import { LoadConsumer } from '@contexts/Load'

const Axios = (props) => {
  const submitAsync = async () => {
    await submit({})
  }

  useEffect(() => {
    const initialize = () => {
      if (props.run) {
        submitAsync()
      }
    }
    initialize()
  }, [props.run])

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  let setLoadCallback = () => true

  const activeLoad = (inLoad) => {
    const { load = true } = props
    if (typeof inLoad === 'function' && load) {
      setLoadCallback = inLoad
    }
  }

  const showLoad = (show) => {
    if (typeof setLoadCallback === 'function') {
      setLoadCallback(show)
    }
  }

  const redirect = (url) => {
    window.location.href = url;
  }

  const error = (error) => {
    const token = localStorage.getItem(configs.TokenSession)
    const message = Utils.getValue(error, 'response.data.message')
    props.onError(error)
    if (message) {
      Toast.set('warning', message);
    }
    if (message === 'TOKEN_EXPIRED' && token) {
       redirect(process.env.REACT_APP_PORTAL_URL)
    }
  }

  const success = ({ data }) => {
    const { onSuccess, noMessage = true } = props
    onSuccess(data)
    setResponse(data)
    showLoad(false)
    if (noMessage && data.message) {
      Toast.set('success', data.message || data)
    }
  }

  const submit = async (data) => {
    const { params } = data || {}
    const { messageLoad } = props
    if (params) props = { ...props, params }
    showLoad(typeof messageLoad === 'string' ? messageLoad : true)
    try {
      setLoading(true)
      const object = (await http(props)) || {}
      success(object)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      showLoad(false)
      error(err)
    }
  }

  const { children } = props
  return (
    <LoadConsumer>
      {({ setLoad: load }) => {
        activeLoad(load)
        if (typeof children === 'function') {
          return children({
            submit,
            response,
            loading
          })
        } else {
          return children
        }
      }}
    </LoadConsumer>
  )
}

Axios.defaultProps = {
  onError: () => true,
  onSuccess: () => true,
}

const http = async ({ token, api, params, others, method = 'get' }) => {
  const { endpoints } = configs.api
  const urlBase = process.env.REACT_APP_API_URL
  let { external, url } = Utils.getValue(endpoints, api) || {}
  const auth = token || localStorage.getItem(configs.TokenSession)
  if (external) {
    url = ApiUtils.getParams(url, others)
    return await axios({
      url,
      method,
      data: { ...params },
      Authorization: {
        "Content-Type": "application/json"
      },
    })
  }
  let headers = {}
  url = `${urlBase}${ApiUtils.getParams(url, others)}`
  if (auth) {
    headers = { Authorization: `Bearer ${auth}` }
  }
  return await axios({
    url,
    method,
    headers,
    data: { ...params },
  })
}

const getApi = (api) => {
  const urlBase = process.env.REACT_APP_API_URL
  const { endpoints } = configs.api
  const { url } = endpoints[api]
  return `${urlBase}${url}`
}

export { getApi, http }
export default Axios
