import React from 'react'
import { NumericFormat } from 'react-number-format';
import { Button, Modal, Grid } from 'semantic-ui-react'


// @import components
import Utils from '@utils'
import Input from '@UI/Input'
import Translate from '@UI/Translate'
import AirlineConstants from '@constants/airline.contants'

import { Formik } from 'formik'
import * as Yup from 'yup'

// @import styles
import './styles.scss'

const AddEdit = (props) => {
  const { Anticipated, Programmed } = AirlineConstants;
  const { airlines, airline = {}, open, close } = props
  const payments = [{ label: Anticipated }, { label: Programmed }]
  const type = Object.keys(props.airline).length ? 'EDIT' : 'ADD'

  const formik = {
    enableReinitialize: true,
    initialValues: { ...airline },
    validationSchema: Yup.object().shape({
      urlPhoto: Yup.string(),
      idAirline: Yup.string(),
      airline: Yup.string().required('REQUIRED'),
      payment: Yup.string().required('REQUIRED'),
      amount: Yup.string().required('REQUIRED'),
      price: Yup.string().required('REQUIRED'),
    }),
  }

  const onSubmit = (item) => {
    const { onAdd, onEdit } = props
    const selectAirline = airlines.find(({ name }) => name === item.airline) || {};
    const object = {
      payment: item.payment,
      airline: selectAirline.name,
      idAirline: selectAirline._id,
      urlPhoto: selectAirline.urlPhoto,
      price: Utils.removeMaskNumber(item.price),
      amount: Utils.removeMaskNumber(item.amount),
    }
    if (type === 'ADD') {
      onAdd(object)
    } else {
      onEdit(object)
    }
  }

  const handleChangePrice = (values, formik) => {
    formik.setFieldValue('price', values.floatValue)
  }

  const handleChangeAmonut = (values, formik) => {
    formik.setFieldValue('amount', values.floatValue)
  }

  const formComponent = (formik) => {
    const { handleBlur, handleChange, values = {}, errors } = formik
    return (
      <Grid stretched stackable columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Input
              {...props}
              type="select"
              name="airline"
              nameText="name"
              nameValue="name"
              array={airlines}
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'airline')}
              error={Utils.getValue(errors, 'airline')}
              helperText={Utils.getValue(errors, 'airline')}
              label="QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_AIRLINE"
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              {...props}
              name="payment"
              type="select"
              array={payments}
              nameValue="label"
              nameText="label"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'payment')}
              error={Utils.getValue(errors, 'payment')}
              helperText={Utils.getValue(errors, 'payment')}
              label="QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_PAYMENT"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <NumericFormat
              name="amount"
              customInput={Input}
              onBlur={handleBlur}
              decimalSeparator=","
              thousandSeparator="."
              isNumericString
              allowNegative={false}
              value={Utils.getValue(values, 'amount')}
              error={Boolean(Utils.getValue(errors, 'amount'))}
              helperText={Utils.getValue(errors, 'amount')}
              label="QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_AMOUNT"
              onValueChange={(values) => handleChangeAmonut(values, formik)}
            />
          </Grid.Column>
          <Grid.Column>
            <NumericFormat
              prefix="R$ "
              name="price"
              customInput={Input}
              onBlur={handleBlur}
              decimalSeparator=","
              thousandSeparator="."
              isNumericString
              allowNegative={false}
              value={Utils.getValue(values, 'price')}
              error={Boolean(Utils.getValue(errors, 'price'))}
              helperText={Utils.getValue(errors, 'price')}
              label="QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_PRICE"
              onValueChange={(values) => handleChangePrice(values, formik)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <Modal open={open} centered={false} size="small" className="AddEdit" onClose={close}>
      <Formik {...formik} onSubmit={onSubmit}>
        {({ handleSubmit, isValid, ...form }) => (
          <>
            <Modal.Header>
              <Translate>{`QUOTATION_AIRLINE_ADD_EDIT_TITLE_${type}`}</Translate>
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>{formComponent(form)}</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <div className="form-footer modal-footer">
                <Button onClick={close}>
                  <Translate>QUOTATION_AIRLINE_ADD_EDIT_CANCEL</Translate>
                </Button>
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>QUOTATION_AIRLINE_ADD_EDIT_SEND</Translate>
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default AddEdit
