import React, { useState } from 'react'

import Utils from '@utils'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Axios from '@UI/Axios'

import Aproved from './components/Aproved'
import ViewUser from './components/ViewUser'
import ViewMiles from './components/ViewMiles'

import PermissionConstant from '@constants/permission.contants'

// @import styles
import './styles.scss'

const ListNews = (props) => {
  const [open, setOpen] = useState(false)
  const [openUser, setOpenUser] = useState(false)
  const [openAproved, setOpenAproved] = useState(false)

  const [quotation, setQuotation] = useState({})

  const headers = [
    { name: 'QUOTATION_TABLE_HEADER_ORIGIN' },
    { name: 'QUOTATION_TABLE_HEADER_DATA' },
    { name: 'QUOTATION_TABLE_HEADER_STATUS' },
    { name: 'QUOTATION_TABLE_HEADER_USER' },
    { name: 'QUOTATION_TABLE_HEADER_EMAIL' },
    { name: 'QUOTATION_TABLE_HEADER_WHATSAPP' },
    { name: 'QUOTATION_TABLE_HEADER_VALUE' },
    { name: 'QUOTATION_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const toggleAproved = () => {
    setOpenAproved(!openAproved)
  }

  const toggleUser = () => {
    setOpenUser(!openUser)
  }

  const toggle = () => {
    setOpen(!open)
  }

  const onView = (quotation, callback) => {
    if (callback) callback()
    setQuotation(quotation)
  }

  const onChange = ({ target: { value } }) => {
    props.setName(value)
  }

  const getStatus = ({ aproved, expired, done }) => {
    let index = 0;
    if (done) index = 3;
    if (expired) index = 2;
    if (aproved) index = 1;
    const status = [
      { color: '#bdc3c7', label: 'Realizada' },
      { color: '#27ae60', label: 'Aprovada' },
      { color: '#c0392b', label: 'Expirada' },
      { color: '#f39c12', label: 'Finalizada' }]
    return (<div className='statusQuotation'>
      <span style={{ backgroundColor: status[index].color }}></span>
      {status[index].label}
    </div>)
  }

  const { load, quotations = [], updateScreen, permission, pagination, params } = props

  const MANAGER = permission === PermissionConstant.MANAGER

  return (
    <div className="ListQuotation">
      <Card title="QUOTATION_CARD_TITLE" category="QUOTATION_CARD_DESCRIPTION">
        <Table
          load={load}
          params={params}
          headers={headers}
          onChange={onChange}
          showHeader={MANAGER}
          pagination={pagination}
          label={'QUOTATION_TABLE_FILTER'}
        >
          {quotations.map((element, index) => (
            <tr key={index}>
              <td>{element.pageAirline ? "CIA's" : 'Home'}</td>
              <td>{Utils.fomartDate(element.date)}</td>
              <td>
                {getStatus(element)}
              </td>
              <td>{element.user.name}</td>
              <td>{element.user.email}</td>
              <td>{element.user.whatsapp}</td>
              <td>{Utils.formatMoney(element.price)}</td>
              <td className="td-options text-center">
                <button onClick={() => onView(element, toggle)} className="button-config" />
                {MANAGER ? (
                  <>
                    {element.done && element.registry && <button onClick={() => onView(element, toggleUser)} className="button-search" />}
                    {element.done && <button style={{ color: 'red' }} onClick={() => onView(element, toggleAproved)} className="button-check" />}
                    <Axios api="quotations" method="delete" others={element._id} onSuccess={updateScreen}>
                      {({ submit }) => <button onClick={submit} className="button-delete" />}
                    </Axios>
                  </>
                ) : null}
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <ViewMiles quotation={quotation} open={open} close={toggle} {...props} />
      <ViewUser quotation={quotation} open={openUser} close={toggleUser} {...props} />
      <Aproved quotation={quotation} open={openAproved} close={toggleAproved} {...props} />
    </div>
  )
}

export default ListNews
